.portion .text { 
    /* width: 0%; */
    margin-left: 5%;
}
.right { 
    margin-right: 5%;
    margin-left: 0 !important;
}
@media screen and (max-width: 768px) {
  .about .portion {
    flex-direction: column-reverse !important;
  }
  .portion .text { 
      width: 80%;
  }
}
