@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,700);
/* GLOBAL  */

*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body {
  position: relative;
  height: 100%;
}
body {
  padding: 0;
  margin: 0;
  background: #000;
  font-family: Poppins, sans-serif;
  font-size: 1.2rem;
  color: #fff;
  overflow-x: hidden;
}
a:link { 
  color: #bafd00;
}
a:visited { 
  color: #496300;
}
/* GLOBAL  */

*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  padding: 0;
  margin: 0;
  background: #000;
  font-family: Poppins, sans-serif;
  font-size: 1.2rem;
  color: #fff;
  height: 100%;

  /* overflow-x: hidden; */
}
.hide {
  display: none;
}
.title {
  color: #bafd00;
  font-size: 48px;
  line-height: 72px;
  font-weight: normal;
  letter-spacing: 4px;
  margin-bottom: 1rem;
  margin-top: 0;
}
.pretitle {
  color: #fff;
  font-size: 32px;
  line-height: 48px;
}

/* HEADER */

.main-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  height: 140px;
  margin: 0 5%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.13);
}
.burger {
  display: none;
  background-color: rgba(255, 255, 255, 0.13);
  padding: 6px;
  border-radius: 25%;
}
.burger div {
  width: 25px;
  height: 3px;
  margin: 5px;
  background-color: #bafd00;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
}

.nav-links {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  min-height: 140px;
  justify-content: space-between;
  align-items: center;
}

.nav-links > li {
  font-size: 16px;
  line-height: 27px;
}
.main-nav {
  display: flex;
  align-items: center;
}
.main-nav a {
  color: inherit;
  text-decoration: none;
  padding: 1rem;
  position: relative;
  text-transform: uppercase;
  font-weight: bold;

  border-bottom: 3px solid transparent;
}
.main-nav a.active {
  border-bottom: 3px solid #bafd00;
}
.main-nav a:hover {
  color: #bafd00 !important;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 1.25em;
  color: inherit;
  text-decoration: none;
}
.logo img {
  height: 100px;
}

.hero {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  height: calc(100vh - 140px);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
}
.player {
  background: none;
  width: 80px;
  height: 80px;
  border: solid 8px #fff;
  border-radius: 100%;
  position: relative;
  text-indent: -9999px;
  box-shadow: 1px 1px 3px #999999;
  box-sizing: content-box;
  box-sizing: initial;
}

.player span {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-left: 20px solid #fff;
  border-bottom: 15px solid transparent;
  position: absolute;
  top: 30%;
  left: 40%;
}

.player:hover {
  background: #bafd00;
  cursor: pointer;
  opacity: 0.8;
  border: none;
  position: absolute;
  width: 90px;
  height: 90px;
  box-shadow: none;
}

.player:hover span {
  top: 35%;
  left: 42%;
}
.inactive {
  background-color: #424242 !important;
  cursor: not-allowed;
  pointer-events: none;
}

.inactive :hover {
}
/* OUR BRANDS */
.our-brands {
  display: flex;
  background: #101010;
  justify-content: space-evenly;
  align-items: center;
  height: 140px;
}
.our-brands img {
  height: 120px;
}
/* ABOUT */
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.btn {
  color: #201f54;
  border: 2px solid #cfff254f !important;
  padding: 0.75rem 3rem;
  text-decoration: none;
  font-size: 0.9rem;
  outline: none;
  border: none;
  cursor: pointer;
  background: #cfff25;
  text-align: center;
  width: 100%;

  margin: 5% auto;
}

.btn:hover {
  background-color: #ff7525 !important;
  color: #fff !important;
}

.btn:focus {
  background-color: #ffaf4f !important;
}
.about .portion {
  display: flex;
  flex-direction: row-reverse;
  margin: 5% 10%;
  justify-content: space-between;
}
.portion .text {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #6c757d;
}
.right {
  flex-direction: row !important;
}
.card-title {
  font-size: 32px;
  line-height: 36px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0;
}

.card-subtitle {
  margin: 0.5rem;
  font-size: 16px;
  line-height: 20px;
  color: #6c757d;
}
.line-v {
  background: #bafd00;
  transform: rotate(90deg);
  width: 10px;
  z-index: -1;
}
/* ABSTRACT */

.abstract {
  margin: 10% 0;
  height: 200px;
  width: 100%;
}

/* IMAGES */
.gallery {
  background: #101010;
  overflow: scroll;
  position: relative;
  text-align: center;
  width: 100%;
}
canvas {
  position: absolute;
}

/* CONTACT */
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.contact input,
textarea {
  background-color: #000;
  border: 3px solid #424242;
  color: #fff;
  width: 30%;
  font-size: 20px;
}
.contact input {
  height: 48px;
}
.contact .btn {
  width: 30%;
}
/* FOOTER */
footer {
  background: #bafd00;
  color: #000;
  margin-top: 10%;
}
.footer-logos {
  display: flex;
  justify-content: space-around;
  padding: 8% 0;
  /* height: 180px; */
}
.affiliate-logo {
  /* margin-top: 25px; */
  width: 200px !important;
  height: 120px !important;
  /* -webkit-filter: grayscale(100%);  */
  /* filter: grayscale(100%); */
}
.footer-main {
  display: flex;
  justify-content: space-evenly;
}

.footer-main div {
  margin: 3%;
}
.footer-main div a {
  color: inherit;
  text-decoration: inherit;
}

/* product section */
section {
  display: flex;
  flex-direction: column;
  margin: 5% 10%;
}

/* card */
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #000;
  justify-content: space-between;
  background-clip: border-box;
  border: 1px solid rgba(221, 221, 221, 0.125);
  max-width: 350px;
  margin: 7px;
}

.shadow-orange:hover {
  box-shadow: 5px 5px rgba(255, 117, 37, 0.4), 10px 10px rgba(255, 117, 37, 0.3),
    15px 15px rgba(255, 117, 37, 0.2), 20px 20px rgba(255, 117, 37, 0.1),
    25px 25px rgba(255, 117, 37, 0.05);
}

.shadow-green:hover {
  box-shadow: 5px 5px rgba(207, 255, 37, 0.4), 10px 10px rgba(207, 255, 37, 0.3),
    15px 15px rgba(207, 255, 37, 0.2), 20px 20px rgba(207, 255, 37, 0.1),
    25px 25px rgba(207, 255, 37, 0.05);
}

.shadow-white:hover {
  box-shadow: 5px 5px rgba(255, 255, 255, 0.4),
    10px 10px rgba(255, 255, 255, 0.3), 15px 15px rgba(255, 255, 255, 0.2),
    20px 20px rgba(255, 255, 255, 0.1), 25px 25px rgba(255, 255, 255, 0.05);
}
.card-actions {
  text-align: right;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.card img {
  max-width: 100%;
  display: block;
  max-height: 200px;

  height: 200px;
}
.text-muted {
  color: #6c757d !important;
  font-size: 12px;
}

.text-green {
  color: #cfff25 !important;
}
.text-orange {
  color: #ff7525 !important;
}
.btn-cart {
  font-size: 10px;
  text-transform: uppercase;
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: #fff;
  background: #000;
  border: 1px solid rgba(221, 221, 221, 0.125);
  cursor: pointer;
}
.btn-cart:hover {
  text-decoration: underline;
}

.add-to-cart {
  padding: 0.75rem 1.25rem;
  color: #000;
  background: #fff;
}

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.product-details {
  flex-direction: row;
  justify-content: space-around;
}
.product-buttons {
  flex-direction: row;
  justify-content: space-between;
}
.product-buttons a {
  width: 30%;
}
.product-description {
  text-align: center;
  color: #8c9196;
}
.custom-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.custom-option {
  display: flex;
  align-self: center;
  flex-direction: column;
  margin: 0 5%;
  text-align: left;
}
.custom-option select {
  background-color: black;
  font-size: 18px;
  text-align-last: center;
  color: #fff;
  height: 35px;
}

.customize-build {
  text-align: center;
}

.cart-main {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.cart-items {
  display: flex;
  flex-direction: column;
  width: 80%;
}
.cart-item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 10%;
}
.cart-total {
  width: 30%;
  border: 2px solid white;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
  max-height: 220px;
}
.clamp-size {
  align-self: flex-start;
  font-size: 14px;
  margin-top: 15px;
}

.clamp-size select {
  height: unset;
  font-size: unset;
}

.custom-option {
  width: 100%;
  align-self: center;
  font-size: 14px;
  margin-top: 15px;
}

.custom-option select {
  height: unset;
  font-size: unset;
  text-transform: capitalize;
}
.list-title {
  padding-left: 10px;
  border-left: 4px solid rgba(207, 255, 37);
  font-size: 1.5rem;
}
.home section {
  margin: 0;
}

.nav-active {
  transform: translateX(0%) !important;
}
@-webkit-keyframes navLinkFade {
  from {
    opacity: 0;
    transition: translateX(50px);
  }
  to {
    opacity: 1;
    transition: translateX(0px);
  }
}
@keyframes navLinkFade {
  from {
    opacity: 0;
    transition: translateX(50px);
  }
  to {
    opacity: 1;
    transition: translateX(0px);
  }
}
.burger-active .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}
.burger-active .line2 {
  opacity: 0;
}
.burger-active .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.portion img {
  width: 40%;
}

.thank-you {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.new-products {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.cart-item .actions {
  display: flex;
  align-self: center;
}
.cart-item .text-muted {
  align-self: center;
}
.product-modal {
  display: inline;
}
@media screen and (max-width: 1024px) {
  .nav-links > li {
    font-size: 12px;
  }
  .logo img {
    width: 120px;
  }
  .main-nav a {
    padding: 1rem;
  }

  .cart-main {
    flex-direction: column;
    align-items: center;
  }
  .cart-total {
    margin-top: 50px;
    width: 80%;
  }
  .cart-item {
    margin: 0;
    flex-direction: column;
  }
  .cart-item .text-muted {
    align-self: flex-start;
  }
  .cart-item .actions {
    align-self: flex-end;
  }
  .footer-logos {
    height: auto;
    height: initial;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {
  body {
    font-size: 1rem;
  }
  .card-subtitle {
    font-size: 12px;
  }
  .main-header {
    height: 100px;
  }
  .nav-links {
    display: none;
  }

  .about {
    justify-content: space-around;
  }
  .burger {
    display: block;
    cursor: pointer;
  }
  .hero {
    background-position: left;
  }

  .our-brands img {
    height: 64px;
    width: 30%;
  }
  .our-brands {
    height: 240px;
    flex-direction: column;
    text-align: center;
  }
  .portion img {
    width: 50%;
  }
  .about .portion {
    align-items: center;
    margin: 0;
    margin-bottom: 3%;
  }
  .btn {
    width: 70%;
    margin: 0 auto;
    font-size: 12px;
    padding: 0.5em;
  }
  .right {
    flex-direction: row-reverse !important;
  }
  .contact input,
  textarea {
    width: 80%;
  }
  .card-title {
    font-size: 16px;
  }
  .product-buttons {
    flex-direction: column;
  }
  .product-buttons a {
    width: 80%;
    margin-bottom: 5%;
  }
  .nav-links {
    z-index: 2;
    position: absolute;
    right: 0;
    height: 85vh;
    top: 100px;
    justify-content: space-around;
    background-color: rgba(0, 0, 0, 0.8);

    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    transform: translateX(100%);
    transition: transform 0.5s ease-in;
  }

  .pretitle {
    font-size: 24px;
  }
  .title {
    font-size: 36px;
  }
  .card-title {
    font-size: 24px;
  }
  .affiliate-logo {
    /* width: 10% !important; */
    /* height: 70px; */
    /* width: initial !important; */
  }
}

@media only screen and (max-width: 1224px) {
  .main-nav a {
    padding: 0.5rem;
  }
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(255, 255, 255, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  color: #bafd00 !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.portion .text { 
    /* width: 0%; */
    margin-left: 5%;
}
.right { 
    margin-right: 5%;
    margin-left: 0 !important;
}
@media screen and (max-width: 768px) {
  .about .portion {
    flex-direction: column-reverse !important;
  }
  .portion .text { 
      width: 80%;
  }
}

.portion .text { 
    /* width: 0%; */
    margin-left: 5%;
}
.right { 
    margin-right: 5%;
    margin-left: 0 !important;
}
@media screen and (max-width: 768px) {
  .about .portion {
    flex-direction: column-reverse !important;
  }
  .portion .text { 
      width: 80%;
  }
}

